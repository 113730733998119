<template>
  <section>
    <div class="title">
      <i
        class="ali-icon ali-icon-action-back"
        @click="backHistory"
      ></i>
      <span style="margin-left:10px">人员出入台账</span>
    </div>
    <section class="search-container" style="border: 1px solid #3f72ba;padding: 10px;">
      <!-- <a-row class="tab-text m-t-24">
                <a @click="typeActiveKey = item.key" :class="item.key == typeActiveKey ? 'active' : ''" v-for="item in typeMap" :key="item.key">{{item.name}}</a>
            </a-row>
            <a-row class="tab-text m-t-14">
                <a @click="typeActiveKey = item.key" :class="item.key == typeActiveKey ? 'active' : ''" v-for="item in typeMap" :key="item.key">{{item.name}}</a>
      </a-row>-->
      <a-form layout="inline" class="m-t-14">
        <a-form-item label="单位名称">
          <a-select class="search-select" placeholder="全部" v-model="searchInfo.companyId">
            <a-select-option value>全部</a-select-option>
            <a-select-option
              v-for="item in companyMap"
              :key="item.id"
              :value="item.id"
            >{{item.companyName}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="人员类型">
          <a-select class="search-select" placeholder="全部" v-model="searchInfo.personnelType">
            <a-select-option value>全部</a-select-option>
            <a-select-option :value="1">管理人员</a-select-option>
            <a-select-option :value="2">劳务管理人员</a-select-option>
            <a-select-option :value="3">施工人员</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="劳务班组">
          <a-select class="search-select" placeholder="全部" v-model="searchInfo.teamId">
            <a-select-option value>全部</a-select-option>
            <a-select-option
              v-for="(item,key) in teamMap"
              :key="key"
              :value="item.id"
            >{{item.teamName}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="标记">
          <a-select class="search-select" placeholder="请选择" v-model="searchInfo.liveBuiltBureau">
            <a-select-option value>全部</a-select-option>
            <a-select-option :value="1">已标记</a-select-option>
            <a-select-option :value="0">未标记</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="进出状态">
          <a-select
            showSearch
            option-filter-prop="children"
            class="search-select"
            placeholder="全部"
            v-model="inOutType"
          >
            <a-select-option value>全部</a-select-option>
            <a-select-option value="1">进入</a-select-option>
            <a-select-option value="2">离开</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="人员姓名">
          <a-input class="search-input" placeholder="请输入" v-model="name" />
        </a-form-item>
        <!-- <a-form-item label="考勤时间">
          <a-range-picker class="search-range-date" v-model="searchInfo.endDate" />
        </a-form-item> -->
        <a-form-item label="考勤时间">
            <a-range-picker
              allowClear
              v-model="priceRangeDate"
              @calendarChange="calendarPriceRangeChange"
              @change="changePriceRangeDate"
            />
             <!--:disabled-date="disabledPriceRangeDate" 
               format="YYYY-MM-DD HH:mm:ss"
              show-time -->
          </a-form-item>
        <a-form-item>
          <a-button type="primary" class="m-r-8" @click="search">
            <a-icon type="search" />查询
          </a-button>
          <a-button class="m-r-8" @click="reset">
            <a-icon type="reload" />重置
          </a-button>
          <a-button class="item" @click="exprotFile('exportPersonnelLogList', {params: selForm})">
                        <a-icon type="export" />
                        导出
                    </a-button>
          <!-- <a-button class="m-r-8"><a-icon type="export" />导出</a-button> -->
        </a-form-item>
      </a-form>
    </section>

    <section class="list-table-container m-t-18" style="border: 1px solid #3f72ba;padding: 10px;height: calc(100% - 140px)">
      <a-table
        :bordered="false"
        :columns="columns"
        :data-source="listMap"
        :pagination="false" :locale="locale"
        style="height:84%;overflow: auto;"
      >
      <!-- @change="changePage" -->
              <!-- :pagination="{total,hideOnSinglePage:true,pageSize:pageSize,showTotal:(total) => `共${total}条`,size:'default',showQuickJumper:true,showSizeChanger:false}" -->
        <span slot="deviceType" slot-scope="text">{{text == 1 ? '门禁设备' : '考勤设备'}}</span>
        <span
          slot="personnelType"
          slot-scope="text"
        >{{text == 1 ? '管理人员' : text == 2 ? '劳务管理人员' :'施工人员'}}</span>
        <span
          slot="inOutType"
          slot-scope="text"
          :class="text == 1 ? 'list-status-in' : 'list-status-out'"
        >{{text == 1 ? '进入' : '离开'}}</span>
        <a-row slot="photoUrl" slot-scope="text">
          <previewImage :data="text" :size="40" />
        </a-row>
      </a-table>
      <div class="content-page pagination-container" v-if="total">
        <a-pagination
          :showQuickJumper="true"
          :showSizeChanger="true"
          :current="pageNum"
          :pageSize="pageSize"
          :total="total"
          :show-total="(total) => `共${total}条`"
          @change="pageNumChange"
          @showSizeChange="pageSizeChange"
        />
      </div>
    </section>
  </section>
</template>

<script>
const columns = [
  { title: '序号', dataIndex: 'key', align: 'center', key: 'key' },
  { title: '出入口名称', dataIndex: 'doorwayName', align: 'center', key: 'doorwayName' },
  { title: '设备类型', dataIndex: 'deviceType', align: 'center', key: 'deviceType', scopedSlots: { customRender: "deviceType" } },
  { title: '设备名称', dataIndex: 'deviceName', align: 'center', key: 'deviceName' },
  { title: '单位名称', dataIndex: 'companyName', align: 'center', key: 'companyName' },
  { title: '人员类型', dataIndex: 'personnelType', align: 'center', key: 'personnelType', scopedSlots: { customRender: "personnelType" } },
  { title: '人员名称', dataIndex: 'personnelName', align: 'center', key: 'personnelName' },
  { title: '职务/工种', dataIndex: 'jobName', align: 'center', key: 'jobName' },
  { title: '刷卡时间', dataIndex: 'createTime', align: 'center', key: 'createTime' },
  { title: '状态', dataIndex: 'inOutType', align: 'center', key: 'inOutType', scopedSlots: { customRender: "inOutType" } },
  { title: '考勤照片', dataIndex: 'photoUrl', align: 'center', key: 'photoUrl', scopedSlots: { customRender: "photoUrl" } }
]
import moment from 'moment'
import previewImage from '@/components/previewImage'
import { exprotFile } from '@/until/unit'
export default {
  data() {
    return {
      columns,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      listMap: [],
      searchInfo: {
        // endDate: []
      },
      name:'',
      inOutType:'',
      priceRangeDate: [
        // moment(this.formatDate(new Date().getTime() - 14 * 86400000, 'Y-m-d')),
        // moment(this.formatDate(new Date().getTime(), 'Y-m-d'))
      ],
      typeMap: [
        { name: '全部', key: 0 },
        { name: '设备名称1', key: 1 },
        { name: '设备名称2', key: 2 },
      ],
      typeActiveKey: 0,
      companyMap: [],
      teamMap: [],
      offsetDays: 1209600 * 1000 //最多选择范围31天ms
    }
  },
  components: { previewImage },
  created() {
    this.init()

  },
  mounted() {
    if(this.$route.query.inOutType){
      this.inOutType = this.$route.query.inOutType;
      this.priceRangeDate = [
        moment(this.formatDate(new Date().getTime(), 'Y-m-d')),
        moment(this.formatDate(new Date().getTime(), 'Y-m-d'))
      ]
    }
    if(this.$route.query.date){
      this.priceRangeDate = [
        moment(this.formatDate(this.$route.query.date, 'Y-m-d')),
        moment(this.formatDate(this.$route.query.date, 'Y-m-d'))
      ]
    }
    if(this.$route.query.name){
      this.name = this.$route.query.name;
    }
    this.queryPersonnelFaceCollectionLog()
  },
  computed:{
    isSet() {
      return this.$route.query.inOutType?this.$route.query.inOutType:null;
    },
    isSetDate() {
      return this.$route.query.date?this.$route.query.date:null;
    },
    isSetName() {
      return this.$route.query.name?this.$route.query.name:null;
    },
    selForm(){
      return {
        current: this.pageNum,
        size: this.pageSize,
        companyId: this.searchInfo.companyId,
        liveBuiltBureau: this.searchInfo.liveBuiltBureau,
        personnelName: this.name,
        personnelType: this.searchInfo.personnelType,
        teamId: this.searchInfo.teamId,
        inOutType:this.searchInfo.inOutType,
        // startDate: this.searchInfo.endDate.length ? this.searchInfo.endDate[0].format('YYYY-MM-DD') : '',
        // endDate: this.searchInfo.endDate.length ? this.searchInfo.endDate[1].format('YYYY-MM-DD') : ''
        startDate: this.priceRangeDate.length ? this.priceRangeDate[0].format("YYYY-MM-DD") : '',
        endDate: this.priceRangeDate.length ? this.priceRangeDate[0].format("YYYY-MM-DD"): ''
      };
    }
  },
  methods: {
    exprotFile,
    init() {
      this.queryCompanyDropDownBox()
      this.queryTeamDropDownBox()
    },
    backHistory() {
       window.history.back()
    },
     //选择完时间
    changePriceRangeDate() {
      this.selectPriceDate = ''
    },
    //选择开始时间/结束时间
    calendarPriceRangeChange(date) {
      this.selectPriceDate = date[0]
    },
    //根据选择的开始时间/结束时间，动态渲染要禁用的日期
    disabledPriceRangeDate(current) {
      if (this.selectPriceDate) {
        let selectV = moment(this.selectPriceDate, 'YYYY-MM-DD').valueOf()
        return current > moment(this.formatDate(new Date(selectV + this.offsetDays).getTime(), 'Y-m-d')) ||
          current < moment(this.formatDate(new Date(selectV - this.offsetDays).getTime(), 'Y-m-d')) ||
          current > moment().endOf('day')
      } else {
        return current > moment().endOf('day')
      }
    },
    formatDate: (timestamp, formatLayout = 'Y-m-d H:i:s') => {
      let formatDate = ""
      formatLayout = formatLayout.toUpperCase()
      timestamp = (timestamp + "").length > 11 ? timestamp : timestamp * 1000
      let time = new Date(timestamp)
      for (let i in formatLayout) {
        if (['Y', 'M', 'D', 'W', 'H', 'I', 'S'].indexOf(formatLayout[i]) >= 0) {
          switch (formatLayout[i]) {
            case 'Y':
              formatDate += time.getFullYear()
              break;
            case 'M':
              formatDate += time.getMonth() >= 9 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1)
              break;
            case 'D':
              formatDate += time.getDate() > 9 ? time.getDate() : '0' + time.getDate()
              break;
            case 'W':
              formatDate += time.getDay() == 0 ? 7 : time.getDay()
              break;
            case 'H':
              formatDate += time.getHours() > 9 ? time.getHours() : '0' + time.getHours()
              break;
            case 'I':
              formatDate += time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes()
              break;
            case 'S':
              formatDate += time.getSeconds() > 9 ? time.getSeconds() : '0' + time.getSeconds()
              break;
          }
        } else {
          formatDate += formatLayout[i]
        }
      }

      return formatDate
    },
    //单位下拉
    queryCompanyDropDownBox() {
      this.$api.queryCompanyDropDownBox().then(res => {
        if (res.code === 200) {
          this.companyMap = res.data || []
        }
      })
    },
    //班组下拉
    queryTeamDropDownBox() {
      this.$api.queryTeamDropDownBox().then(res => {
        if (res.code === 200) {
          this.teamMap = res.data || []
        }
      })
    },
    //考勤列表
    queryPersonnelFaceCollectionLog() {
      let params = {
        current: this.pageNum,
        size: this.pageSize,
        companyId: this.searchInfo.companyId,
        liveBuiltBureau: this.searchInfo.liveBuiltBureau,
        personnelType: this.searchInfo.personnelType,
        teamId: this.searchInfo.teamId,
        inOutType:this.searchInfo.inOutType,
        // startDate: this.searchInfo.endDate.length ? this.searchInfo.endDate[0].format('YYYY-MM-DD') : '',
        // endDate: this.searchInfo.endDate.length ? this.searchInfo.endDate[1].format('YYYY-MM-DD') : ''
      }
      if ((this.priceRangeDate && this.priceRangeDate.length)||this.isSetDate!=null) {
        if(this.priceRangeDate && this.priceRangeDate.length){
          params.startDate = this.priceRangeDate[0].format("YYYY-MM-DD");
          params.endDate = this.priceRangeDate[1].format("YYYY-MM-DD");
        }else{
          params.startDate = moment(this.formatDate(this.$route.query.date, 'Y-m-d')).format("YYYY-MM-DD");
          params.endDate = moment(this.formatDate(this.$route.query.date, 'Y-m-d')).format("YYYY-MM-DD");
        }
      }
      console.log('this.searchInfo.inOutType===>',this.searchInfo.inOutType);
      // console.log('==========>',params);
      if(this.isSet!=null||this.inOutType!=''){
        params.inOutType = this.inOutType;
        //  params.inOutType = this.isSet;
      }
      if(this.isSetName!=null||this.name==''){
        if(this.name!=''){
          params.personnelName = this.name;
        }
      }
      this.$api.queryPersonnelFaceCollectionLog(params).then(res => {
        if (res.code === 200) {

          this.listMap = (res.data && res.data.records) || []
          this.total = (res.data && res.data.total) || 0
          this.listMap.forEach((item, ind) => {
            item.key = (this.pageSize * (this.pageNum - 1)) + ind + 1
          })
        }
      })
    },
    reset() {
      this.searchInfo = {
        endDate: []
      }
      this.pageNum = 1
      this.queryPersonnelFaceCollectionLog()
    },
    search() {
      this.pageNum = 1
      this.queryPersonnelFaceCollectionLog()
    },
    // //分页
    // changePage(p) {
    //   this.pageNum = p.current
    //   this.queryPersonnelFaceCollectionLog()
    // }
    //分页
    changePageNum(p) {
      this.pageNum = p.current
    },
    pageNumChange(current) {
      this.pageNum = current;
      this.queryPersonnelFaceCollectionLog();
    },
    pageSizeChange(current, size) {
      this.pageNum = 1;
      this.pageSize = size;
      this.queryPersonnelFaceCollectionLog();
    },
  }
}
</script>

<style lang="scss" scoped>
.list-status-in {
  color: #52c41a;
  &::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 8px;
    background: #52c41a;
  }
}
.list-status-out {
  color: #e25745;
  &::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 8px;
    background: #e25745;
  }
}
.title {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  letter-spacing: 2px;
  padding: 0 12px;
  margin-bottom: 5px;
}
</style>